<template>
    <div class="w-full">
        <div>
            <div v-for="cat in list_cat" :key="cat.name">
                <div v-on:click="selectCat(cat)" class="click" style="margin-bottom:5px;">
                    <!--<vs-button type="gradient" class="w-full">{{ cat.name }}</vs-button>-->
                    <vs-button color="rgb(109, 173, 249)" gradient-color-secondary="rgb(62, 98, 140)" type="gradient" class="w-full">{{ cat.name }}</vs-button>
                </div>
            </div>
        </div>

        <vs-popup title="Catalogue" :active.sync="popupActive">
            <ul class="custom_list">
                <div class="centerx default-input">
                    <vs-input class="inputx w-full" label="Quantité" v-model="nb" type="number" />
                </div>
                <br/><br/>

                <li class="cursor" v-on:click="selectItem(item)" v-for="item in list_item_cat" :key="item.name" style="height:50px;">
                    <b style="float:right;">{{ item.ttc.toFixed(2) }} €</b>
                    <div>
                        <span class="overflow">{{ item.produit.substring(0,30) }}</span>
                        <template v-if="item.credit>0">
                            <br/>
                            <span class="click">
                                    ( Crédit +{{ item.credit }}
                                <template v-if="item.day>0">
                                    , Durée: {{ item.day }} J
                                </template>
                                )
                            </span>
                        </template>
                    </div>
                </li>
            </ul>
        </vs-popup>
    </div>
</template>

<style lang="scss">
    .vs-list--slot{
        margin-left:0 !important;
    }
    .custom_list{
        li{
            border:1px solid #dbdbdb;
            border-top:none;
            border-left:none;
            border-right:none;
            margin-top:10px;
            height:30px;
        }
        li:last-child{
            border-bottom:none;
        }
    }
</style>

<script>

import Catalogue from '@/database/models/catalogue'

export default {
    components:{
	},
    props: {
    },
    data(){
        return{
            popupActive:false,

            nb:1,
            list_cat:[],
            list_item_cat:[],
        }
    },
    watch:{
        $route (){
        },
    },
    beforeMount:function()
    {
        Catalogue.getAllMemory((cat)=>
        {
            this.list_cat = []
            //get categorie
            for( var c in cat )
            {
                //find double ?
                let posi = this.list_cat.map(function(e){ 
                    return e.name; 
                }).indexOf( cat[c].categorie )
                
                if(posi==-1)
                    this.list_cat.push({name:cat[c].categorie, data:[]})
            }
            
            //remplisage data
            for( var i=0; i<this.list_cat.length; i++)
            {
                for( var n in cat)
                if( this.list_cat[i].name == cat[n].categorie)
                    this.list_cat[i].data.push( cat[n] )
            }

            //triage ABC 
            this.list_cat.sort(function(a, b)
            {
                var ca  = a.name.toUpperCase();
                var cb  = b.name.toUpperCase();
                return ('' + ca).localeCompare(''+cb);
            });

            //triage ABC sous data
            for( i=0; i<this.list_cat.length; i++)
            this.list_cat[i].data.sort(function(a, b)
            {
                var ca  = a.produit.toUpperCase();
                var cb  = b.produit.toUpperCase();
                return ('' + ca).localeCompare(''+cb);
            });
            //console.log( this.list_cat )
        })
    },
    methods:{
        selectCat( cat )
        {
            this.list_item_cat = cat.data
            this.nb = 1;
            this.popupActive = true
        },
        selectItem( item )
        {
            //console.log( item )
            this.popupActive = false
            let data = { ...item, ...{nb:this.nb} }
            this.$emit('selected', data )
        }
    }
}

</script>